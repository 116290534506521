import React from "react";
import { Col } from "react-bootstrap";

export const Card = ({ title, description, imgUrl, collaborators, homepage, githubUrl }) => {
  return (
    <Col sm={6} md={4}>
      <div className="proj-imgbx">
        <div className="proj-txtx">
          <div className="info-project">
            <h4>{title}</h4>
            <span>{description}</span>
            <a
              href={homepage}
              target="_blank"
              key={homepage}
              rel="noreferrer"
            >{homepage}</a>
            <a href={githubUrl} target="_blank" rel="noopener noreferrer">
              Dépôt GitHub
            </a>
          </div>
          <div className="collab">
            <p>
              Collab:
              {collaborators.map((collaborator) => (
                <a
                  href={collaborator.html_url}
                  target="_blank"
                  key={collaborator.login}
                  rel="noreferrer"
                >
                  {collaborator.name},
                </a>
              ))}
            </p>
          </div>
        </div>
      </div>
    </Col>
  );
};

export default Card;
