import React, { useState } from "react";
import { Container, Row, Col } from 'react-bootstrap';
import headerImg from "../assets/img/moi.jpg";

export const Banner = () => {
    const [activeLink, setActiveLink] = useState("home");

    const onUpdateActiveLink = (value, event) => {
        setActiveLink(value);
        if (value === "connect") {
            const connectSection = document.getElementById("connect");
            connectSection.scrollIntoView({ behavior: "smooth" })
        }
    }

    return (
        <section className="banner" id='home'>
            <Container>
                <Row className='align-items-center'>
                    <Col xs={12} md={6} xl={7}>
                        <span className="tagline">Bienvenue</span>
                        <h1><span className="wrap">Bonjour, je suis Victor Vega, développeur fullstack.</span></h1>
                        <button className={
                            activeLink === "connect" ? "active navbar-link" : "navbar-link"
                        }
                            onClick={(e) => onUpdateActiveLink("connect", e)}>Me contacter</button>
                    </Col>
                    <Col xs={12} md={6} xl={5}>
                        <img src={headerImg} alt="Headder Img" />
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default Banner;