import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import navIcon1 from '../assets/img/nav-icon1.svg';
//import navIcon2 from '../assets/img/nav-icon2.svg';
//import navIcon3 from '../assets/img/nav-icon3.svg';

export const Footer = () => {
    return (
        <footer className="footer">
            <Container>
                <Row className='align-item-center'>
                    <Col sm={6}>
                        <h2 className="logo">VV</h2>
                    </Col>
                    <Col sm={6} className='text-center text-sm-end'>
                        <div className='social-icon'>
{/*                           <a href="https://portfolio-vega-victor.vercel.app/"><img src={navIcon2} alt='social icon' /></a>
                            <a href="https://portfolio-vega-victor.vercel.app/"><img src={navIcon3} alt='social icon' /></a>*/} 
                            <a href="https://portfolio-vega-victor.vercel.app/"><img src={navIcon1} alt='social icon' /></a>
                        </div>
                        <p>CopyRight 2023. All Right Reserved</p>
                    </Col>
                </Row>
            </Container>
        </footer>
    );
};

export default Footer;