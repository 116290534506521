import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

import colorSharp from "../assets/img/color-sharp.png";

export const Skills = () => {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);

  useEffect(() => {
    fetch("https://hubofvegavictor.com/api/skills")
      .then((res) => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
          setItems(result);
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      );
  }, []);
  function getColor(data) {
    const colors = ["#5830b3", "#7e339a", "#a13582", "#7e339a", "#5830b3"];

    return colors[data % colors.length];
  }

  const renderCarrousel = () => {
    if (error) {
      return <div> Comming soon </div>;
    } else if (!isLoaded) {
      return <div> Loading... </div>;
    } else {
      return (
        <Carousel
          responsive={responsive}
          infinite={true}
          className="skill-slider"
        >
          {items.map((item) => (
            <div className="item">
              <div className="circular-progress-skills">
                <CircularProgressbar
                  value={item.percent}
                  maxValue={100}
                  text={`${item.name}`}
                  styles={{
                    path: {
                      // Path color
                      stroke: getColor(item.id),
                      // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                      strokeLinecap: "round",
                      // Customize transition animation
                      transition: "stroke-dashoffset 0.5s ease 0s",
                      // Rotate the path
                      transform: "rotate(0.25turn)",
                      transformOrigin: "center center",
                    },
                    trail: {
                      // Trail color
                      stroke: "none",
                      // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                      strokeLinecap: "butt",
                      // Rotate the trail
                      transform: "rotate(0.25turn)",
                      transformOrigin: "center center",
                    },
                    text: {
                      // Text color
                      fill: "#fff",
                      // Text size
                      fontSize: "12px",
                      fontWeight: "bold",
                    },
                  }}
                />
              </div>
        
            </div>
          ))}
        </Carousel>
      );
    }
  };

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  return (
    <section className="skill" id="skills">
      <Container>
        <Row>
          <Col>
            <div className="skill-bx">
              <h2>Compétences</h2>
              <p>
                Voici répertoriées toutes les compétences et langages que j'ai
                acquis au cours de ces 1 an et demi de formation et au cours de
                mes projets personnels.
              </p>
              {renderCarrousel()}
            </div>
          </Col>
        </Row>
      </Container>
      <img src={colorSharp} alt="couleur de fond pour les competences" className="background-image-left" />
    </section>
  );
};

export default Skills;
