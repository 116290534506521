/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Col, Container, Row, Nav, Tab } from "react-bootstrap";
import projImg1 from "../assets/img/project-img1.png";
import ProjectCard from "./Card";
import colorSharp2 from "../assets/img/color-sharp2.png";
import { Octokit } from "octokit";

export const Projects = () => {
  const [repos, setRepos] = useState([]);
  const [collaborators, setCollaborators] = useState({});

  const authKey = "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";

  // const sendRepoNameToServer = async (repoName) => {
  //   try {
  //     const response = await fetch("http://localhost:3001/project/add", {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: {name: repoName} ,
  //     });

  //     if (response.ok) {
  //       console.log("Nom du dépôt enregistré avec succès !");
  //     } else {
  //       console.error(
  //         "Échec de l'enregistrement du nom du dépôt :",
  //         await response.text()
  //       );
  //     }
  //   } catch (error) {
  //     console.error("Erreur lors de la requête au serveur :", error);
  //   }
  // };

  useEffect(() => {
    const octokit = new Octokit({
      auth: authKey,
    });

    octokit
      .request("GET /user/repos?sort=created&direction=desc", {
        headers: {
          "X-GitHub-Api-Version": "2022-11-28",
        },
      })
      .then((response) => {
        setRepos(response.data);
        response.data.forEach((repo) => {
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    repos.forEach((repo) => {
      const octokit = new Octokit({
        auth: authKey,
      });
      octokit
        .request(`GET /repos/${repo.owner.login}/${repo.name}/collaborators`, {
          headers: {
            "X-GitHub-Api-Version": "2022-11-28",
          },
        })
        .then((response) => {
          setCollaborators((prevState) => ({
            ...prevState,
            [repo.name]: response.data.map((collaborator) => {
              return {
                name: collaborator.login,
                html_url: collaborator.html_url,
              };
            }),
          }));
        })
        .catch((error) => {
          console.error(error);
        });
    });
  }, [repos]);

  const projects = repos.map((repo) => {
    const repoCollaborators = collaborators[repo.name] || [];

    return {
      title: repo.name,
      description: repo.description,
      imgUrl: projImg1,
      collaborators: repoCollaborators,
      homepage: repo.homepage,
      githubUrl: repo.html_url
    };
  });

  const renderProjectCards = (projects) => {
    const tabPanes = [];
    let currentPane = [];
    let cardCount = 0;

    projects.forEach((project, index) => {
      currentPane.push(<ProjectCard key={index} {...project} />);
      cardCount++;

      if (cardCount === 6 || index === projects.length - 1) {
        tabPanes.push(
          <Tab.Pane key={tabPanes.length} eventKey={tabPanes.length + 1}>
            <Row>{currentPane}</Row>
          </Tab.Pane>
        );
        currentPane = [];
        cardCount = 0;
      }
    });

    return tabPanes;
  };

  return (
    <section className="project" id="project">
      <Container>
        <Row>
          <Col>
            <h2>Projets</h2>
            <Tab.Container id="projects-tabs" defaultActiveKey="1">
              <Nav
                variant="pills"
                className="nav-pills mb-5 justify-content-center align-items-center"
                id="pills-tab"
              >
                {projects.length > 6 &&
                  Array.from({ length: Math.ceil(projects.length / 6) }).map(
                    (_, index) => (
                      <Nav.Item key={index}>
                        <Nav.Link eventKey={index + 1}>{index + 1}</Nav.Link>
                      </Nav.Item>
                    )
                  )}
              </Nav>
              <Tab.Content>{renderProjectCards(projects)}</Tab.Content>
            </Tab.Container>
          </Col>
        </Row>
      </Container>
      <img
        src={colorSharp2}
        alt="backgroundImage"
        className="background-image-right"
      />
    </section>
  );
};

export default Projects;
