import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import contactImg from "../assets/img/contact-img.svg";
import { toast, Toaster } from 'react-hot-toast';


export const Contact = () => {

  const formInitialDetails = {
    name: "",
    lastname: "",
    email: "",
    phone: "",
    message: "",
  };


  const [formDetails, setFormDetails] = useState(formInitialDetails);
  const [buttonText, setButtonText] = useState("Envoyer");
  const [status, setStatus] = useState({});

  const onFormUpdate = (category, value) => {
    setFormDetails({
      ...formDetails,
      [category]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formDetails);
    try {
      const response = await fetch("https://hubofvegavictor.com/api/send-email-contact", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(formDetails)
      });
      if (response.ok) {
        setFormDetails(formInitialDetails);
        setButtonText("Envoyé");
        setStatus({ success: true, message: "E-mail envoyé avec succès." })
        toast.success("Demande contact envoyé avec succé")
      } else {
        const errorMessage = await response.text();
        setStatus({ success: false, message: errorMessage });
      }
    } catch (error) {
      console.error("Erreur lors de l'envoi de la demande de contact: ", error);
      setStatus({ success: false, message: "Erreur lors de l'envoi de la demande de contact" })
    }
  }


  return (
    <section className="contact" id="connect">
      <Container>
        <Row className="align-items-center">
          <Col md={6}>
            <img src={contactImg} alt="Contact Us" />
          </Col>
          <Col md={6}>
            <h2>Prendre contact</h2>
            <form onSubmit={handleSubmit}>
              <Row>
                <Col sm={6} className="px-1">
                  <input
                    type="text"
                    value={formDetails.name}
                    placeholder="Prénom"
                    onChange={(e) => onFormUpdate("name", e.target.value)}
                  />
                </Col>
                <Col sm={6} className="px-1">
                  <input
                    type="text"
                    value={formDetails.lastname}
                    placeholder="Nom de famille"
                    onChange={(e) => onFormUpdate("lastname", e.target.value)}
                  />
                </Col>
                <Col sm={6} className="px-1">
                  <input
                    type="email"
                    value={formDetails.email}
                    placeholder="Adresse e-mail"
                    onChange={(e) => onFormUpdate("email", e.target.value)}
                  />
                </Col>
                <Col sm={6} className="px-1">
                  <input
                    type="tel"
                    value={formDetails.phone}
                    placeholder="Numéro de téléphone"
                    onChange={(e) => onFormUpdate("phone", e.target.value)}
                  />
                </Col>
                <Col>
                  <textarea
                    rows="6"
                    value={formDetails.message}
                    placeholder="Message"
                    onChange={(e) => onFormUpdate("message", e.target.value)}
                  />
                  <button type="submit">
                    <span> {buttonText} </span>
                  </button>
                </Col>
              </Row>
            </form>
          </Col>
        </Row>
        <Toaster
          position="top-left"
          reverseOrder={false}
        />
      </Container>
    </section>
  );
};

export default Contact;
