import React, { useState, useEffect } from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import navIcon1 from "../assets/img/nav-icon1.svg";

export const NavBar = () => {
  const [activeLink, setActiveLink] = useState("home");
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const onUpdateActiveLink = (value, event) => {
    setActiveLink(value);
    // Scroll vers le composant Projets
    if (value === "project") {
      const projectsSection = document.getElementById("project");
      projectsSection.scrollIntoView({ behavior: "smooth" });
    }
    if (value === "connect") {
      const connectSection = document.getElementById("connect");
      connectSection.scrollIntoView({behavior: "smooth"})
    }
    if (value === "skills") {
      const skillsSection = document.getElementById("skills");
      skillsSection.scrollIntoView({ behavior: "smooth" })
    }
    if (value === "home") {
      const homeSection = document.getElementById("home");
      homeSection.scrollIntoView({ behavior: "smooth" })
    }
  
    // Empêcher le comportement par défaut du lien
    event.preventDefault();
  };

  return (
    <Navbar expand="lg" className={scrolled ? "scrolled" : ""}>
      <Container>
        <Navbar.Brand href="home">
          <h2 className="logo">VV</h2>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link
              className={
                activeLink === "home" ? "active navbar-link" : "navbar-link"
              }
              onClick={(e) => onUpdateActiveLink("home", e)}
            >
              Accueil
            </Nav.Link>
            <Nav.Link
              className={
                activeLink === "skills" ? "active navbar-link" : "navbar-link"
              }
              onClick={(e) => onUpdateActiveLink("skills", e)}
            >
              Compétences
            </Nav.Link>
            <Nav.Link
              className={
                activeLink === "project" ? "active navbar-link" : "navbar-link"
              }
              onClick={(e) => onUpdateActiveLink("project", e)}
            >
              Projets
            </Nav.Link>
          </Nav>
          <span className="navbar-text">
            <div className="social-icon">
              <a href="https://www.linkedin.com/in/victor-vega-663864209/" target="blanck">
                <img src={navIcon1} alt="social icon" />
              </a>
              {/* <a href="https://portfolio-vega-victor.vercel.app/">
                <img src={navIcon3} alt="social icon" />
              </a> */}
            </div>
            <button className={
              activeLink === "connect" ? "active navbar-link" : "navbar-link"
            }
              onClick={(e) => onUpdateActiveLink("connect", e)}>
              <span>Me contacter</span>
            </button>
          </span>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavBar;
